$('.testi1').owlCarousel({
    loop: true,
    margin: 30,
    nav: false,
    dots: true,
    autoplay: true,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        1024: {
            items: 3
        }
    }
});
$('.shops').owlCarousel({
    loop: true,
    center:false,
    nav: true,
    dots: false,
    autoplay: false,
    responsiveClass: true,
    stagePadding: 20,
    margin:10,
    responsive: {
        0: {
            items: 1,
            nav: false,
            dots: false,
        },
        765: {
            items: 3,
            nav: false,
            dots: false,
        },
        1024: {
            items: 4,
            nav: true,
            dots: false,
        }
    }
});
